require('./bootstrap');

require('alpinejs');

require('@fortawesome/fontawesome-free/js/all.min.js');

window.$ = require('jquery');

$(document).ready(function() {
    $('#loader').fadeOut(200);
});
// const Swal = require('sweetalert2')

